<template>
  <BModal
    ref="loadingSubmit"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <BRow class="px-1 mb-2 pt-2">
        <BCol cols="12" class="mb-2">
            <h3 class="text-black mb-1">
                <strong>
                    Tunggu beberapa saat
                </strong>
            </h3>
            <span class="text-black">
                Orderan kamu sedang kami unggah, mohon tunggu
                <br>
                sampe progress selesai ya...
            </span>
        </BCol>
        <BCol cols="12">
            <div class="d-flex justify-content-end w-100">
                <span class="text-success">
                  <strong>
                    {{ successCount }} orderan
                  </strong>
                </span>
            </div>
            <BRow class="align-items-center justify-content-between">
                <BCol cols="auto" class="pr-0">
                    <BImg src="https://storage.googleapis.com/komerce/assets/icons/export.svg" />
                </BCol>
                <BCol cols="10" class="pl-0">
                    <BProgress :value="progressValue" :max="max" height="20px" variant="success"></BProgress>
                </BCol>
            </BRow>
        </BCol>
    </BRow>
  </BModal>
</template>

<script>
export default {
  props: ['progressValue', 'successCount'],
  data() {
    return {
      max: 100,
    }
  },
  methods: {
    handleRunLoading() {
      this.$refs.loadingSubmit.show()
    },
    close() {
      this.$refs.loadingSubmit.hide()
    },
  },
}
</script>

<style></style>
